<template>
  <div id="intro">
    <div class="cover">
      <img :src="cover" />
    </div>

    <div class="section">
      <h1>{{ title }}</h1>

      <router-link :to="profileLink(authorInfo.authorId)" class="person-link">
        <span class="person-wrapper">
          <span class="person-image">
            <img :src="appointAvatar(userpic, authorInfo.authorId)" />
          </span>
          <Username
            :wrap-class="'person-name'"
            :user-address="authorInfo.authorAddress"
            :username="authorInfo.authorNickname"
          />
        </span>
      </router-link>

      <div class="meta meta-collection">
        <div class="collection-info">
          <div>
            <span>Items</span>
            <span>{{ collectionInfo.collectionItems }}</span>
          </div>
          <div>
            <span>Owners</span>
            <span>{{ collectionInfo.collectionOwners }}</span>
          </div>
          <div>
            <span>Floor Price</span>
            <span
              >{{
                formatNumber(collectionInfo.collectionFloorPrice, true)
              }}
              USD</span
            >
          </div>
          <div>
            <span>Total Sales</span>
            <span
              >{{
                formatNumber(collectionInfo.collectionVolumeTraded, true)
              }}
              USD</span
            >
          </div>
        </div>

        <div class="tools" v-if="tools">
          <router-link :to="editLink" class="svg edit">
            <svg><use xlink:href="#svg-edit" /></svg>
          </router-link>

          <div class="svg delete" @click="$emit('deleteModal')">
            <svg><use xlink:href="#svg-delete" /></svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Username from "@/components/common/Username";
import formatNumberMixin from "@/mixins/formatNumberMixin";
import appointAvatarMixin from "@/mixins/appointAvatarMixin";
import useLinkMixin from "@/mixins/useLinkMixin";
export default {
  name: "CollectionIntro",
  mixins: [formatNumberMixin, appointAvatarMixin, useLinkMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    cover: {
      type: String,
      required: true,
    },
    userpic: {
      type: String,
      required: true,
    },
    authorInfo: {
      required: false,
    },
    collectionInfo: {
      required: false,
    },
    tools: {
      required: false,
    },
    editLink: {
      type: String,
      required: false,
    },
  },
  components: { Username },
};
</script>
