var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.collection)?_c('div',[(_vm.showDeleteModal)?_c('DeleteModal',{attrs:{"state":'collection'},on:{"close":function($event){_vm.showDeleteModal = false},"accept":_vm.deleteCollection}}):_vm._e(),_c('CollectionIntro',{attrs:{"title":_vm.collectionTitle,"cover":_vm.collection.fileUrl,"userpic":_vm.collectionOwnerAvatar,"author-info":{
      authorId: _vm.collectionOwnerId,
      authorNickname: _vm.collectionOwnerNickname,
      authorAddress: _vm.collectionOwnerAddress,
    },"collection-info":{
      collectionItems: _vm.collectionItems,
      collectionOwners: _vm.collectionOwners,
      collectionFloorPrice: _vm.collectionFloorPrice,
      collectionVolumeTraded: _vm.collectionVolumeTraded,
    },"tools":_vm.showUpdateCollectionBtn,"edit-link":_vm.editCollectionLink(_vm.collectionId)},on:{"deleteModal":function($event){_vm.showDeleteModal = true}}}),_vm._m(0),_c('div',{staticClass:"tiles section"},[_c('div',{staticClass:"flex"},_vm._l((_vm.items),function(item,index){return _c('TokenCard',{key:index,attrs:{"token":item}})}),1),_c('ScrollLoader',{attrs:{"loader-method":_vm.getNftTokenByCollectionId,"loader-disable":_vm.disableLoading}},[_c('img',{attrs:{"width":"200","height":"200","src":require("@/assets/img/preloader.svg")}})]),(!_vm.items.length && !_vm.loading)?_c('NoRecordsFound'):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section",attrs:{"id":"subintro"}},[_c('div',{staticClass:"tabs"},[_c('div',{staticClass:"active"},[_vm._v("NFTs")]),_c('div',[_vm._v("Description")])])])
}]

export { render, staticRenderFns }