<template>
  <div v-if="collection">
    <DeleteModal
      :state="'collection'"
      v-if="showDeleteModal"
      @close="showDeleteModal = false"
      @accept="deleteCollection"
    />

    <CollectionIntro
      :title="collectionTitle"
      :cover="collection.fileUrl"
      :userpic="collectionOwnerAvatar"
      :author-info="{
        authorId: collectionOwnerId,
        authorNickname: collectionOwnerNickname,
        authorAddress: collectionOwnerAddress,
      }"
      :collection-info="{
        collectionItems,
        collectionOwners,
        collectionFloorPrice,
        collectionVolumeTraded,
      }"
      :tools="showUpdateCollectionBtn"
      :edit-link="editCollectionLink(collectionId)"
      @deleteModal="showDeleteModal = true"
    />

    <div id="subintro" class="section">
      <div class="tabs">
        <div class="active">NFTs</div>
        <div>Description</div>
      </div>
    </div>

    <div class="tiles section">
      <div class="flex">
        <TokenCard v-for="(item, index) in items" :key="index" :token="item" />
      </div>
      <ScrollLoader
        :loader-method="getNftTokenByCollectionId"
        :loader-disable="disableLoading"
        ><img width="200" height="200" src="@/assets/img/preloader.svg" />
      </ScrollLoader>
      <NoRecordsFound v-if="!items.length && !loading" />
    </div>
  </div>
</template>

<script>
import CollectionIntro from "../components/common/CollectionIntro";
import TokenCard from "../components/common/TokenCard";
import NoRecordsFound from "@/components/common/NoRecordsFound";
import InitCollectionMixin from "@/mixins/collection/initCollectionMixin";
import { mapGetters } from "vuex";
import DeleteModal from "@/components/modals/DeleteModal";
import DeleteCollectionMixin from "@/mixins/collection/deleteCollectionMixin";
import useLinkMixin from "@/mixins/useLinkMixin";

export default {
  name: "CollectionPage",
  mixins: [InitCollectionMixin, DeleteCollectionMixin, useLinkMixin],
  computed: {
    ...mapGetters({
      getAddress: "wallet/getAddress",
    }),
    showUpdateCollectionBtn() {
      return (
        this.getAddress &&
        this.getAddress.toLowerCase() === this.collectionOwnerAddress
      );
    },
  },
  data: () => ({
    showDeleteModal: false,
  }),
  components: {
    DeleteModal,
    NoRecordsFound,
    CollectionIntro,
    TokenCard,
  },
};
</script>

<style scoped></style>
